<template>
  <div>

    <div class="text-center">
      <img src="@/assets/images/recovery.svg" class="pt-md-4 pb-md-2 lutin mb-3">
      <div class="row justify-content-center">
        <div class="col-md-5 col-lg-5">
          <div class="font-weight-bold mb-3">
            <el-card-title :title="$t('user.password.title')" />
          </div>
          <div class="alert alert-success font-weight-bold text-left py-2" v-if="formSuccess !== false">
            <i class="icon-check mr-2"></i>
            <span v-html="formSuccess"></span>
          </div>
        </div>
      </div>
      <form v-if="formSuccess === false" @submit.prevent="resetPassword" class="form row justify-content-center align-items-center">
        <div class="col-md-6 col-xxl-5">
          <div class="alert alert-warning font-weight-bold text-left py-2" v-if="formError !== false">
            <i class="icon-warning mr-2"></i>
            <span v-html="formError"></span>
          </div>
          <div class="form-group form-group-feedback form-group-feedback-left">
            <input required autocomplete="email" v-model="email" type="email" class="form-control" :placeholder="$t('user.password.email')"/>
            <div class="form-control-feedback">
              <i class="icon-envelop3 text-muted"></i>
            </div>
            <button v-if="!loading" type="submit" class="mt-3 w-100 btn btn-success text-white btn-lg">
              {{ $t('user.password.password') }}
            </button>
            <div v-if="loading" class="bg-white pt-4 pb-2">
              <loader />
            </div>
          </div>
        </div>
      </form>
      <div class="pb-3">
        <router-link class="font-weight-semibold text-secondary border-bottom" tag="a" :to="{ path: '/login', query: { email }}">{{ $t('user.password.login_link') }}</router-link>
      </div>

    </div>

  </div>
</template>

<script>

import { AUTH_PASSWORD_RESET } from '../../store/mutations-types'

export default {
  name: 'PasswordResetForm',
  data () {
    return {
      email: this.$route.query && this.$route.query.email ? this.$route.query.email : '',
      loading: false,
      formSuccess: false,
      formError: false
    }
  },
  methods: {
    resetPassword: function () {
      this.loading = true
      this.formError = false
      this.formSuccess = false
      const { email } = this
      this.$store.dispatch('auth/' + AUTH_PASSWORD_RESET, email).then(data => {
        this.loading = false
        this.formSuccess = data.data.success
      })
      .catch(errors => {
        this.loading = false
        this.formError = ''
        _.toArray(errors.response.data.errors).forEach( (element, index) => {
          this.formError+= '' + element[0] + '<br/>'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.lutin {
  max-width: 220px;
}
</style>
