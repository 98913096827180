<template>
  <div>

    <div class="text-center">
      <img src="@/assets/images/recovery.svg" class="pt-md-4 pb-md-2 lutin mb-3">
      <div class="row justify-content-center">
        <div class="col-md-5 col-lg-5">
          <div class="font-weight-bold mb-2">
            <h3 class="mb-3">{{ $t('user.password.title_new') }}</h3>
          </div>
        </div>
      </div>
      <form @submit.prevent="renewPassword" class="form row justify-content-center align-items-center">
        <div class="col-md-5 col-lg-5">
          <div class="alert alert-warning font-weight-bold text-left py-2" v-if="formError !== false">
            <i class="icon-warning mr-2"></i>
            <span v-html="formError"></span>
          </div>
          <div class="form-group form-group-feedback form-group-feedback-left">
            <input required autocomplete="new-password" v-model="password" type="password" class="form-control" :placeholder="$t('user.password.password_field')"/>
            <div class="form-control-feedback">
              <i class="icon-lock2 text-muted"></i>
            </div>
          </div>
          <div class="form-group form-group-feedback form-group-feedback-left">
            <input required autocomplete="new-password" v-model="password_confirmation" type="password" class="form-control" :placeholder="$t('user.password.password_confirmation_field')"/>
            <div class="form-control-feedback">
              <i class="icon-lock2 text-muted"></i>
            </div>
            <button v-if="!loading" type="submit" class="mt-3 w-100 btn btn-primary text-white btn-lg">
              {{ $t('user.password.password') }}
            </button>
            <div v-if="loading" class="bg-white pt-4 pb-2">
              <span class="text-primary"><loader></loader></span>
            </div>
          </div>
        </div>
      </form>
      <div class="pb-3">
        <router-link class="font-weight-bold text-grey" tag="a" to="/login">{{ $t('user.password.login_link') }}</router-link>
      </div>

    </div>

  </div>
</template>

<script>

import _ from 'lodash'
import { AUTH_PASSWORD_RENEW } from '../../store/mutations-types'

export default {
  name: 'PasswordResetForm',
  data () {
    return {
      password: '',
      password_confirmation: '',
      loading: false,
      formError: false
    }
  },
  methods: {
    renewPassword: function () {
      this.loading = true
      this.formError = false
      const { password, password_confirmation } = this
      this.$store.dispatch('auth/' + AUTH_PASSWORD_RENEW, {
        password: password,
        password_confirmation: password_confirmation,
        token: this.$route.params.token
      }).then(() => {
        this.loading = false
        this.$router.push('/')
      })
      .catch(errors => {
        this.loading = false
        this.formError = ''
        if (errors.response && errors.response.data && errors.response.data.errors) {
          _.toArray(errors.response.data.errors).forEach( (element) => {
            this.formError+= '' + element[0] + '<br/>'
          })
        } else {
          this.formError = errors
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.lutin {
  max-width: 220px;
}
</style>
